import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {DefnStudioPickPluginId} from "../../../../api/meta/base/dto/DefnStudioPickPluginId";
import {StudioEntPluginMap} from "../../../../api/meta/base/dto/StudioEntPluginMap";
import {PluginBundleId} from "../../../../api/meta/base/Types";
import {DtoPluginBundle} from "../../../../api/studio/base/dto/DtoPluginBundle";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldStudioPickPluginVersionId(props: {
  defn: DefnStudioPickPluginId
})
{
  const formCtx = useFormCtx();
  const formStore = formCtx.getStore();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const pluginBundleId = defn.pluginBundleId;

  const pluginBundleMap = formStore?.pluginBundleMap;
  const pluginMap = formStore?.pluginMap;

  const optionMap = useMemo(() => getOptionMap(
      pluginBundleId,
      pluginBundleMap,
      pluginMap
    ), [
      pluginBundleMap,
      pluginMap,
      pluginBundleId
    ]
  );

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPick
        {...props}
        optionMap={optionMap}
        fieldProps={fieldProps}
      />}
    />
  );
}

function getOptionMap(
  pluginBundleId: PluginBundleId,
  pluginBundleMap?: Record<PluginBundleId, DtoPluginBundle>,
  pluginMap?: StudioEntPluginMap
)
{
  const mapOfOption = {
    keys: [] as string[],
    map: {} as Record<string, DefnDtoOption>
  } as DefnStudioMapOfDtoOption;

  if(pluginBundleMap)
  {
    Object.values(pluginBundleMap).forEach(pluginBundle =>
    {
      if(pluginBundle.pluginBundleId !== pluginBundleId)
      {
        return;
      }

      const pluginVersionMap = pluginBundle.pluginVersionMap;

      Object.values(pluginVersionMap).forEach(pluginVersion =>
      {
        if(pluginVersion)
        {
          const pluginId = pluginVersion.pluginId;

          mapOfOption.keys.push(pluginId);
          mapOfOption.map[pluginId] = {
            metaId: pluginId,
            value: pluginVersion.pluginVersion ?? ""
          };
        }
      });
    });
  }

  if(pluginMap)
  {
    pluginMap.keys.forEach(pluginId =>
    {
      const plugin = pluginMap.map[pluginId];
      const _pluginId = plugin.pluginId;

      if(plugin.pluginBundleId !== pluginBundleId)
      {
        return;
      }

      if(!mapOfOption.map[_pluginId])
      {
        mapOfOption.keys.push(_pluginId);
        mapOfOption.map[_pluginId] = {
          metaId: _pluginId,
          value: plugin.pluginVersion
        };
      }
    });
  }

  return mapOfOption;
}
