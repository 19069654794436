import _ from "lodash";
import {isPluginId} from "../../../api/meta/base/ApiPlus";
import {isEntId} from "../../../api/meta/base/ApiPlus";
import {ArtifactId} from "../../../api/meta/base/Types";
import {PluginBundleId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {ISigAcceptor} from "../../../api/nucleus/base/ISigAcceptor";
import {SigDone} from "../../../api/nucleus/base/sig/SigDone";
import {MsgStudioEntCreate} from "../../../api/studio/studioDrawer/msg/MsgStudioEntCreate";
import {MsgStudioPluginCreate} from "../../../api/studio/studioDrawer/msg/MsgStudioPluginCreate";
import {RpcStudioDrawer} from "../../../api/studio/studioDrawer/RpcStudioDrawer";
import {SigStudioEntAvatar} from "../../../api/studio/studioDrawer/sig/SigStudioEntAvatar";
import {SigStudioPluginAvatar} from "../../../api/studio/studioDrawer/sig/SigStudioPluginAvatar";
import ISrvc from "../../../base/ISrvc";
import {SelectList} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {dispatchListItemStudio} from "../../../base/plus/SrvcPlus";
import {random} from "../../../base/plus/StringPlus";
import {hasEditLockPlugin} from "../../../base/plus/StudioPlus";
import {hasEditLockEnt} from "../../../base/plus/StudioPlus";
import {listSetIfExistSecondaryLine} from "../../../base/slices/list/SliceListAPSAActions";
import {listSetCanShowMenu} from "../../../base/slices/list/SliceListSharedActions";
import {listSetSelectedItemId} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListBinderTwo} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListData} from "../../../base/types/list/TypesList";
import {CbSuccess} from "../../../base/types/TypesGlobal";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

type TypeSigStudioItemAvatar = SigStudioEntAvatar | SigStudioPluginAvatar | undefined;

const subscriberId = "SrvcStudioDrawerRecentList";

export default class SrvcStudioDrawerRecentList extends ISrvc
{
  listTopicRpcEntDelete = "listTopicRpcEntDelete";

  constructor(readonly selectList: SelectList)
  {
    super();
  }

  getListBinder()
  {
    return {
      selectSourceItem1: this.selectSourceItem.bind(this),
      onBindSourceItem1: this.onBindSourceItem.bind(this),
      selectSourceItem2: this.selectEditLock.bind(this),
      onBindSourceItem2: this.onBindEditLock.bind(this)
    } as IListBinderTwo<TypeSigStudioItemAvatar, boolean>;
  }

  subscribe(artifactId: ArtifactId, unSubscribe?: boolean)
  {
    if(isEntId(artifactId) || isPluginId(artifactId))
    {
      if(isEntId(artifactId))
      {
        Srvc.app.pubsub.caller.entAvatarAdmin(subscriberId, artifactId, unSubscribe);
      }
      else if(isPluginId(artifactId))
      {
        Srvc.app.pubsub.caller.pluginAvatarAdmin(subscriberId, artifactId, unSubscribe);
      }
      Srvc.app.pubsub.studio.studioAdminEditLock(subscriberId, artifactId, unSubscribe);
    }
  }

  load(
    listName: string,
    entIdAdminSet: EntId[],
    pluginIdAdminSet: PluginBundleId[],
    filter?: EntId[],
    hideMenu?: boolean)
  {
    if(!filter || filter.length === 0)
    {
      const listData = {
        itemIds: [...entIdAdminSet, ...pluginIdAdminSet],
        itemsById: {},
        version: random()
      } as IListData;
      dispatchList(listName, listRefresh(listData));
    }
    else
    {
      const listData = {
        itemIds: [..._.intersection(entIdAdminSet, filter), ..._.intersection(pluginIdAdminSet, filter)],
        itemsById: {},
        version: random()
      } as IListData;
      dispatchList(listName, listRefresh(listData));
    }
    if(hideMenu)
    {
      dispatchList(listName, listSetCanShowMenu(false));
    }
  }

  setRecentListIdSelected(listName: string, oldSelectedItemId?: ArtifactId)
  {
    if(oldSelectedItemId)
    {
      dispatchList(listName, listSetSelectedItemId(oldSelectedItemId));
    }
  }

  rpcEntCreate(msg: MsgStudioEntCreate, cbSuccess: CbSuccess)
  {
    RpcStudioDrawer.studioEntCreate(msg, cbProgress =>
    {
      if(cbProgress.error)
      {
        Srvc.app.toast.showErrorToast(cbProgress);
      }
      else
      {
        cbSuccess();
      }
    });
  }

  rpcPluginCreate(msg: MsgStudioPluginCreate, cbProgress: ISigAcceptor<SigDone>)
  {
    RpcStudioDrawer.studioPluginCreate(msg, cbProgress);
  }

  //region private

  private selectSourceItem(state: RootState, itemId: TypeListItemId)
  {
    if(isEntId(itemId))
    {
      return state.cache.app.caller.entIdAdminAvatarMap[itemId];
    }
    else
    {
      return state.cache.app.caller.pluginIdAdminAvatarMap[itemId];
    }
  }

  private onBindSourceItem(listName: string, itemId: TypeListItemId, avatar?: TypeSigStudioItemAvatar): void
  {
    dispatchListItemStudio(listName, itemId, avatar);
  }

  private selectEditLock(state: RootState, itemId: TypeListItemId)
  {
    if(isEntId(itemId))
    {
      return hasEditLockEnt(state, itemId);
    }
    else if(isPluginId(itemId))
    {
      return hasEditLockPlugin(state, itemId);
    }
  }

  private onBindEditLock(listName: string, itemId: TypeListItemId, hasLock?: boolean): void
  {
    if(isEntId(itemId) || isPluginId(itemId))
    {
      dispatchList(listName, listSetIfExistSecondaryLine({
        itemId: itemId,
        icon4: hasLock ? "unlock" : undefined
      }));
    }
  }

  //endregion
}
