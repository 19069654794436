// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {AnyString} from "./AnyString";
import {SysId} from "./SysId";

export type ServiceName =
  | "agent"
  | "api"
  | "aside"
  | "base"
  | "cli"
  | "deeplink"
  | "doc"
  | "drawer"
  | "ent"
  | "entAside"
  | "entDrawer"
  | "entMain"
  | "main"
  | "metric"
  | "otp"
  | "rpc"
  | "scheduler"
  | "session"
  | "stem"
  | "store"
  | "studioDrawer"
  | "studioEnt"
  | "studioMain"
  | "user"
  | "wsoc";

export const AVATAR_CROP_QUALITY = 1;
export const AVATAR_MAX_MB = 1.5;
export const AVATAR_SIZE_MAX = 640;
export const AVATAR_SIZE_MIN = 192;
export const PREFIX_ADMIN_ID = "ea";
export const PREFIX_AUTOMATION_EXECUTION_ID = "ae";
export const PREFIX_CONN_ID = "conn";
export const PREFIX_DEVICE_ID = "d";
export const PREFIX_ENT_ID = "e";
export const PREFIX_ENT_USER_ID = "eu";
export const PREFIX_GHOST_ID = "gh";
export const PREFIX_GROUP_ID = "g";
export const PREFIX_INBOX_ID_FOLLOWER = "ii";
export const PREFIX_INBOX_ID_MASTER = "im";
export const PREFIX_INBOX_MESSAGE_ID = "imm";
export const PREFIX_MEDIA_ID_AUDIO = "ma";
export const PREFIX_MEDIA_ID_AVATAR = "mp";
export const PREFIX_MEDIA_ID_DOCUMENT = "md";
export const PREFIX_MEDIA_ID_IMAGE = "mi";
export const PREFIX_MEDIA_ID_JAR = "mj";
export const PREFIX_MEDIA_ID_STICKER = "ms";
export const PREFIX_MEDIA_ID_THUMBNAIL = "mt";
export const PREFIX_MEDIA_ID_VIDEO = "mv";
export const PREFIX_MEDIA_ID_VOICE = "mo";
export const PREFIX_MESSAGE_ID = "m";
export const PREFIX_META_ID_ACTION = "metaAction";
export const PREFIX_META_ID_AUTOMATION = "metaAutomation";
export const PREFIX_META_ID_CHART_ITEM = "metaChartItem";
export const PREFIX_META_ID_CHART_X_AXIS = "metaChartXAxis";
export const PREFIX_META_ID_CHART_Y_AXIS = "metaChartYAxis";
export const PREFIX_META_ID_CODE = "metaCode";
export const PREFIX_META_ID_CONDITION = "metaCondition";
export const PREFIX_META_ID_DEEPLINK = "metaDeeplink";
export const PREFIX_META_ID_DRIVE_SHEET = "metaDriveSheet";
export const PREFIX_META_ID_EVENT = "metaEvent";
export const PREFIX_META_ID_FIELD = "metaField";
export const PREFIX_META_ID_FIELD_DYNAMIC_CONDITION = "metaFieldDynamicCondition";
export const PREFIX_META_ID_FIELD_DYNAMIC_RULE = "metaFieldDynamicRule";
export const PREFIX_META_ID_FOOTER = "metaFooter";
export const PREFIX_META_ID_FORM = "metaForm";
export const PREFIX_META_ID_FORMULA = "metaFormula";
export const PREFIX_META_ID_FUNC_ARG = "metaFuncArg";
export const PREFIX_META_ID_GRID = "metaGrid";
export const PREFIX_META_ID_GROUP = "metaGroup";
export const PREFIX_META_ID_HEADER = "metaHeader";
export const PREFIX_META_ID_HYPERLINK = "metaHyperlink";
export const PREFIX_META_ID_LAYOUT_DRIVE_SHEET = "metaLayoutDriveSheet";
export const PREFIX_META_ID_LAYOUT_FORM_CONTENT = "metaLayoutFormContent";
export const PREFIX_META_ID_LAYOUT_GRID = "metaLayoutGrid";
export const PREFIX_META_ID_MAPPING = "metaMapping";
export const PREFIX_META_ID_MODULE = "metaModule";
export const PREFIX_META_ID_OPTION = "metaOption";
export const PREFIX_META_ID_PARTITION = "metaPartition";
export const PREFIX_META_ID_PAYMENT_PROVIDER = "metaPaymentProvider";
export const PREFIX_META_ID_PIPELINE_VAR = "metaPipelineVar";
export const PREFIX_META_ID_PLUGIN = "metaPlugin";
export const PREFIX_META_ID_PLUGIN_RESOURCE = "metaPluginResource";
export const PREFIX_META_ID_PROMPT = "metaPrompt";
export const PREFIX_META_ID_REPORT = "metaReport";
export const PREFIX_META_ID_ROLE = "metaRole";
export const PREFIX_META_ID_SECTION = "metaSection";
export const PREFIX_META_ID_SPREADSHEET = "metaSpreadsheet";
export const PREFIX_META_ID_STEP = "metaStep";
export const PREFIX_META_ID_SWIMLANE = "metaSwimlane";
export const PREFIX_META_ID_TAB = "metaTab";
export const PREFIX_META_ID_TABLE_STYLE = "metaTableStyle";
export const PREFIX_META_ID_TRANSLATION = "metaTranslation";
export const PREFIX_META_ID_USER_CONDITION = "metaUserCondition";
export const PREFIX_META_ID_VAR = "metaVar";
export const PREFIX_META_ID_VISIBILITY_ACTION = "metaVisibilityAction";
export const PREFIX_META_ID_VISIBILITY_CONDITION = "metaVisibilityCondition";
export const PREFIX_META_ID_VISIBILITY_RULE = "metaVisibilityRule";
export const PREFIX_META_ID_WIZARD = "metaWizard";
export const PREFIX_PLUGIN_API_ID = "pa";
export const PREFIX_PLUGIN_BUNDLE_ID = "pb";
export const PREFIX_PLUGIN_ID = "p";
export const PREFIX_PLUGIN_RESOURCE_ID = "pr";
export const PREFIX_REPORT_EXECUTION_ID = "re";
export const PREFIX_REQUEST_ID = "req";
export const PREFIX_ROW_ID = "r";
export const PREFIX_SCHEDULER_TASK_ID = "st";
export const PREFIX_SHEET_ID = "s";
export const PREFIX_SPREADSHEET_PARTITION_ID = "sp";
export const PREFIX_STORE_ITEM_ID = "si";
export const PREFIX_TAB_ID = "t";
export const PREFIX_USER_ID = "u";
export const PREFIX_TRANSACTION_ID = "tr";
export const SUFFIX_TEMPLATE = ".template";
export const THUMBNAIL_MAX_MB = 0.25;
export const THUMBNAIL_SIZE = 144;

export type AdminId = SysId;
export type ArtifactId = SysId;
export type AutomationExecutionId = SysId;
export type ChatId = SysId;
export type ConnId = SysId;
export type ContactId = ChatId;
export type DeviceId = SysId;
export type EntId = ArtifactId;
export type EntUserId = ContactId;
export type GhostId = SysId;
export type GroupId = ContactId;
export type InboxId = SysId;
export type InboxIdFollower = InboxId;
export type InboxIdMaster = InboxId;
export type InboxMessageId = SysId;
export type MediaId = SysId;
export type MediaIdAudio = MediaId;
export type MediaIdAvatar = MediaId;
export type MediaIdDocument = MediaId;
export type MediaIdIcon = MediaId;
export type MediaIdImage = MediaId;
export type MediaIdJar = MediaId;
export type MediaIdSticker = MediaId;
export type MediaIdThumbnail = MediaId;
export type MediaIdVideo = MediaId;
export type MediaIdVoice = MediaId;
export type MessageId = SysId;
export type MetaId = SysId;
export type MetaIdAction = MetaId;
export type MetaIdAutomation = MetaId;
export type MetaIdChartItem = MetaId;
export type MetaIdChartXAxis = MetaId;
export type MetaIdChartYAxis = MetaId;
export type MetaIdCode = MetaId;
export type MetaIdComp = MetaId;
export type MetaIdComposite = MetaIdComp;
export type MetaIdCondition = MetaId;
export type MetaIdDeeplink = MetaId;
export type MetaIdDriveSheet = MetaId;
export type MetaIdEvent = MetaId;
export type MetaIdField = MetaIdComp;
export type MetaIdFieldDynamicCondition = MetaId;
export type MetaIdFieldDynamicRule = MetaId;
export type MetaIdFooter = MetaId;
export type MetaIdForm = MetaId;
export type MetaIdFormula = MetaId;
export type MetaIdFuncArg = MetaId;
export type MetaIdGrid = MetaIdComposite;
export type MetaIdGroup = MetaId;
export type MetaIdHeader = MetaId;
export type MetaIdHyperlink = MetaId;
export type MetaIdLayoutDriveSheet = MetaId;
export type MetaIdLayoutForm = MetaId;
export type MetaIdLayoutGrid = MetaId;
export type MetaIdMapping = MetaId;
export type MetaIdModule = MetaId;
export type MetaIdOption = MetaId;
export type MetaIdPartition = MetaId;
export type MetaIdPaymentProvider = MetaId;
export type MetaIdPipelineVar = MetaId;
export type MetaIdPlugin = MetaId;
export type MetaIdPluginResource = MetaId;
export type MetaIdPrompt = MetaId;
export type MetaIdReport = MetaId;
export type MetaIdRole = MetaId;
export type MetaIdSection = MetaIdComposite;
export type MetaIdSpreadsheet = MetaId;
export type MetaIdStep = MetaId;
export type MetaIdSwimlane = MetaId;
export type MetaIdTab = MetaIdComposite;
export type MetaIdTableStyle = MetaId;
export type MetaIdTranslation = MetaId;
export type MetaIdUserCondition = MetaId;
export type MetaIdVar = MetaId;
export type MetaIdVisibilityAction = MetaId;
export type MetaIdVisibilityCondition = MetaId;
export type MetaIdVisibilityRule = MetaId;
export type MetaIdWizard = MetaIdComposite;
export type PluginApiId = SysId;
export type PluginBundleId = ArtifactId;
export type PluginId = SysId;
export type PluginResourceId = SysId;
export type ReportExecutionId = SysId;
export type RequestId = SysId;
export type RowId = ChatId;
export type SchedulerTaskId = SysId;
export type SheetId = SysId;
export type SpreadsheetPartitionId = SysId;
export type StoreItemId = ArtifactId;
export type TabId = SysId;
export type TransactionId = SysId;
export type UserId = SysId;
export type AnyEmailId = AnyString;
export type AnyKey = AnyString;
export type AnyName = AnyString;
export type AnyTime = AnyValue;
export type AnyValue = AnyString;
export type ColumnPath = AnyValue;
export type CurrencyKey = AnyKey;
export type GeoPoint = AnyString;
export type HandleKey = AnyKey;
export type LanguageKey = AnyKey;
export type SearchPath = AnyString;
export type Symbol = AnyString;
export type SymbolColumn = AnyValue;
export type SymbolGrid = AnyValue;
export type TimeZoneKey = AnyKey;
export type DtoLogTreeKeyValueType =
  | "neoQL"
  | "json"
  | "javascript"
  | "xml";

export type EnumDefnAdminDoNotOptionEnt =
  | "about"
  | "actions"
  | "admins"
  | "automations"
  | "dashboard"
  | "deeplinks"
  | "deploy"
  | "manage"
  | "payment"
  | "deployPayment"
  | "deployPlugins"
  | "deployVariables"
  | "driveSheets"
  | "formEditContentLayout"
  | "formEditDetails"
  | "formEditFormLayouts"
  | "formEditFormulas"
  | "formEditPermissions"
  | "formEditVisibility"
  | "forms"
  | "governance"
  | "groups"
  | "plugins"
  | "prompts"
  | "reports"
  | "roles"
  | "spreadsheets"
  | "translations"
  | "users"
  | "variables"
  | "virtualAssistant"
  | "adminModules";

export type EnumDefnAdminDoNotOptionPlugin =
  | "admins";

export type EnumDefnAdminDoNotOptionStoreItem =
  | "admins";

export type EnumDefnAdminPermissionType =
  | "doNotEdit"
  | "doNotShow";

export type EnumDefnArgBinder =
  | "argument"
  | "context"
  | "derived"
  | "field"
  | "input"
  | "output"
  | "spreadsheet"
  | "sep"
  | "variable"
  | "constant"
  | "response";

export type EnumDefnArgBinderArgument =
  | "selectedSectionId"
  | "selectedGridId"
  | "selectedCompositeId"
  | "selectedGridRowId";

export type EnumDefnArgBinderContext =
  | "caller"
  | "callerSetting"
  | "ent"
  | "form"
  | "plugin"
  | "row";

export type EnumDefnArgBinderContextCaller =
  | "color"
  | "entUserId"
  | "handle"
  | "managerId"
  | "assistantIds"
  | "allAssistantIds"
  | "allManagerIds"
  | "grandManagerId"
  | "nickName"
  | "userId"
  | "email"
  | "mobileNumber"
  | "roles";

export type EnumDefnArgBinderContextEnt =
  | "about"
  | "id"
  | "name";

export type EnumDefnArgBinderContextForm =
  | "id"
  | "name"
  | "label";

export type EnumDefnArgBinderContextPlugin =
  | "about"
  | "id"
  | "name";

export type EnumDefnArgBinderContextRow =
  | "createdBy"
  | "createdOn"
  | "id"
  | "order"
  | "parentId"
  | "type"
  | "updatedBy"
  | "updatedOn";

export type EnumDefnAudioFormat =
  | "ogg"
  | "mp3"
  | "wav";

export type EnumDefnAutomationSource =
  | "currentForm"
  | "currentGrid";

export type EnumDefnAutomationTerminateKind =
  | "terminate"
  | "resume"
  | "setField";

export type EnumDefnAutomationWebhookKind =
  | "razorpayPaymentReceipt"
  | "neomeComment"
  | "location";

export type EnumDefnButtonTargetType =
  | "saveToSpreadsheet"
  | "invokePlugin"
  | "callReport"
  | "triggerAction";

export type EnumDefnCalculateFormulaMode =
  | "automatic"
  | "manual"
  | "onSend";

export type EnumDefnCaptureMode =
  | "manual"
  | "onInsert"
  | "onUpdate";

export type EnumDefnCaptureValueKind =
  | "captureLocation"
  | "captureTime"
  | "captureUser";

export type EnumDefnCodeEditorLanguage =
  | "text"
  | "html"
  | "javascript"
  | "json"
  | "neoQL"
  | "sql"
  | "xml";

export type EnumDefnCodeType =
  | "barCode"
  | "qrCode";

export type EnumDefnCompType =
  | "bool"
  | "date"
  | "decimal"
  | "logDecimal"
  | "image"
  | "label"
  | "number"
  | "logNumber"
  | "paragraph"
  | "text"
  | "enumAdminDoNotOptionEnt"
  | "enumAdminDoNotOptionPlugin"
  | "enumAudioFormat"
  | "enumAutomationSource"
  | "enumCaptureValueKind"
  | "enumCodeEditorLanguage"
  | "enumConditionOperator"
  | "enumConjunction"
  | "enumDataPartitionPeriod"
  | "enumDate"
  | "enumDay"
  | "enumDeeplinkConstraints"
  | "enumDeployVar"
  | "enumDeviceSize"
  | "enumDeviceType"
  | "enumDocFileExt"
  | "enumDriveStatus"
  | "enumDurationUnit"
  | "enumEntLockBehavior"
  | "enumEntLockReason"
  | "enumFields"
  | "enumFuncArgs"
  | "enumMapPinShape"
  | "enumPaymentMethod"
  | "enumPaymentPlan"
  | "enumPermission"
  | "enumPluginApiMethod"
  | "enumPluginResources"
  | "enumPluginSecurityAccess"
  | "enumPromptAction"
  | "enumRoles"
  | "enumRowAuditTrail"
  | "enumSetupKind"
  | "enumUserSettingOptions"
  | "enumUserSettingValue"
  | "enumVideoFormat"
  | "enumVisibilityOperator"
  | "enumMonth"
  | "enumQuarter"
  | "enumDeeplinkExpiry"
  | "enumForms"
  | "enumLogOperationKind"
  | "enumCodeType"
  | "enumPosition"
  | "enumDateOccurrence"
  | "enumFrequencyKind"
  | "enumRenderingKind"
  | "enumInsertVariant"
  | "enumUpdateVariant"
  | "enumRemoveVariant"
  | "enumEmptyFieldVariant"
  | "enumTableLayoutStyle"
  | "enumUserProperty"
  | "enumRowProperty"
  | "enumStoreItem"
  | "enumCaptureMode"
  | "enumLockOperation"
  | "enumRefreshOn"
  | "enumEditorLayoutRenderingMode"
  | "enumFormContentLayoutType"
  | "enumSetOfUserKind"
  | "enumUserContext"
  | "enumDynamicOperator"
  | "enumTargetType"
  | "enumDriveSheetLayoutFor"
  | "enumDriveSheetFieldLayoutOn"
  | "enumTextStyle"
  | "enumContentAlignment"
  | "enumUserProps"
  | "enumGridRenderingMode"
  | "enumPaymentMethodKind"
  | "enumSortOrder"
  | "enumTextValidationPattern"
  | "enumSyncMode"
  | "enumMapRenderingMode"
  | "enumCalculateFormulaMode"
  | "enumPluginMode"
  | "enumArgBinderContext"
  | "enumArgBinder"
  | "enumKindAction"
  | "enumKindAutomation"
  | "enumKindButton"
  | "enumKindDeeplink"
  | "enumKindFormComposite"
  | "enumKindHyperlink"
  | "enumKindImport"
  | "enumKindRating"
  | "enumKindReport"
  | "enumKindScheduledEvent"
  | "enumKindAutomationStep"
  | "enumKindSpreadsheetEvent"
  | "enumKindPluginWebhookEvent"
  | "enumKindWebhookEvent"
  | "enumKindTranslation"
  | "enumTerminateSetting"
  | "enumAutomationWebhookKind"
  | "enumLayoutCardFilterKind"
  | "enumLayoutGridKind"
  | "enumThemeButtonSize"
  | "enumThemeButtonVariant"
  | "enumThemeColor"
  | "enumThemeColorShade"
  | "enumThemeDirection"
  | "enumThemeDividerThickness"
  | "enumThemeFieldMargin"
  | "enumThemeFieldSize"
  | "enumThemeFieldVariant"
  | "enumThemeFormVariant"
  | "enumThemeImageCorner"
  | "enumThemePickVariant"
  | "enumThemePickMultiVariant"
  | "enumPlacement"
  | "enumThemeSectionVariant"
  | "enumThemeStroke"
  | "enumThemeTabVariant"
  | "enumVisibilityAction"
  | "enumVisibilityActionOn"
  | "currency"
  | "icon"
  | "language"
  | "timeZone"
  | "pinShape"
  | "lineStroke"
  | "month"
  | "quarter"
  | "textSize"
  | "paymentStatus"
  | "messageKind"
  | "chipSet"
  | "chipSetDate"
  | "chipSetDateTime"
  | "chipSetDay"
  | "chipSetDeviceSize"
  | "chipSetDeviceType"
  | "setOfLanguageKeys"
  | "chipSetTime"
  | "pickRole"
  | "pickText"
  | "pickTree"
  | "pickUser"
  | "pickGridRow"
  | "pickReportRow"
  | "pickOption"
  | "setOfRole"
  | "setOfUser"
  | "setOfText"
  | "color"
  | "hyperlink"
  | "audio"
  | "camera"
  | "counter"
  | "logCounter"
  | "dateRange"
  | "dateTime"
  | "dateTimeRange"
  | "duration"
  | "email"
  | "handle"
  | "location"
  | "mobileNumber"
  | "rating"
  | "signature"
  | "slider"
  | "time"
  | "video"
  | "voice"
  | "geoPoint"
  | "rowId"
  | "symbol"
  | "button"
  | "divider"
  | "document"
  | "error"
  | "html"
  | "identifier"
  | "info"
  | "propertyMap"
  | "ref"
  | "refUser"
  | "refReport"
  | "scanCode"
  | "showCode"
  | "userId"
  | "dynamic"
  | "hyperlinkRow"
  | "grid"
  | "section"
  | "tab"
  | "wizard"
  | "dateFormat"
  | "studioVarIdTextEditor"
  | "studioVarIdParagraphEditor"
  | "studioCodeEditor"
  | "pickActionId"
  | "pickCompId"
  | "pickPluginCompId"
  | "pickFieldId"
  | "pickPluginFieldId"
  | "pickFormId"
  | "pickPluginFormId"
  | "pickGridId"
  | "pickImportPluginId"
  | "pickImportPluginApiId"
  | "pickLayoutFormContentId"
  | "pickLayoutGridId"
  | "pickLayoutSpreadsheetId"
  | "pickPluginBundleId"
  | "pickPluginId"
  | "pickReportId"
  | "pickSectionId"
  | "pickSpreadsheetId"
  | "pickVarId"
  | "pickGroupId"
  | "pickDeeplinkId"
  | "pickPipelineVarId"
  | "pickDeployPaymentProviderId"
  | "studioBuildAllModules"
  | "studioBuildArgBinder"
  | "studioSetOfDate"
  | "studioBuildColor"
  | "studioBuildDate"
  | "studioBuildDateTime"
  | "studioBuildPermissionMatrix"
  | "studioBuildTree"
  | "studioBuildUserSetting"
  | "studioBuildActionPermission"
  | "studioBuildPropertyMap"
  | "studioMapOfForwardRolePermission"
  | "studioMapOfCondition"
  | "studioMapOfFormula"
  | "studioMapOfFuncArg"
  | "studioMapOfJarFile"
  | "studioMapOfLayoutSpreadsheet"
  | "studioMapOfLayoutGrid"
  | "studioMapOfText"
  | "studioMapOfVisibilityCondition"
  | "studioMapOfVisibilityAction"
  | "studioMapOfPartition"
  | "studioMapOfForwardGroupPermission"
  | "studioMapOfDynamicRule"
  | "studioMapOfPipelineVariable"
  | "studioMapOfDynamicCondition"
  | "studioMapOfUserCondition"
  | "studioMapOfLayoutDriveSpreadsheet"
  | "studioFieldMappingTree"
  | "studioGridMappingTree"
  | "studioMapOfArgBinder"
  | "studioSetOfDocFileExt"
  | "studioSetOfStoreItemCategory"
  | "studioSetOfModule"
  | "studioSetOfNumber"
  | "studioSetOfAdminDoNotOption"
  | "studioSetOfPluginSecurityAccess"
  | "studioSetOfRowAuditTrail"
  | "studioSetOfMonth"
  | "studioSetOfBorder"
  | "studioSetOfCompId"
  | "studioSetOfDataExportKind"
  | "studioSetOfActionId"
  | "studioSetOfFieldId"
  | "studioSetOfFieldRefId"
  | "studioSetOfFormId"
  | "studioSetOfGridId"
  | "studioSetOfGroupId"
  | "studioSetOfLayoutFormContentId"
  | "studioSetOfLayoutGridId"
  | "studioSetOfLayoutSpreadsheetId"
  | "studioSetOfReportId"
  | "studioSetOfSectionId"
  | "studioSetOfSpreadsheetId"
  | "studioSetOfVarId"
  | "studioCompArray"
  | "otp"
  | "avtar"
  | "carousel"
  | "formList"
  | "formListItem"
  | "password";

export type EnumDefnConditionOperator =
  | "hasNoValue"
  | "equalTo"
  | "greaterThan"
  | "greaterThanOrEqualTo"
  | "hasValue"
  | "lessThan"
  | "lessThanOrEqualTo"
  | "notEqualTo";

export type EnumDefnConjunction =
  | "or"
  | "and";

export type EnumDefnContentAlignment =
  | "start"
  | "center"
  | "end";

export type EnumDefnDataExportKind =
  | "xlsx"
  | "json";

export type EnumDefnDataPartitionPeriod =
  | "daily"
  | "hourly"
  | "weekly"
  | "monthly"
  | "quarterly"
  | "yearly";

export type EnumDefnDate =
  | "lastQuarter"
  | "lastWeek"
  | "lastMonth"
  | "lastYear"
  | "nextQuarter"
  | "nextWeek"
  | "nextMonth"
  | "nextYear"
  | "now"
  | "tomorrow"
  | "yesterday"
  | "startOfWeek"
  | "startOfMonth"
  | "startOfYear"
  | "endOfWeek"
  | "endOfMonth"
  | "endOfYear"
  | "createdOn"
  | "updatedOn";

export type EnumDefnDateOccurrence =
  | "custom"
  | "first"
  | "last";

export type EnumDefnDay =
  | "sunday"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday";

export type EnumDefnDeeplinkConstraint =
  | "enforceGlobalUserSignIn"
  | "enforceEnterpriseUserSignIn"
  | "makeAnEnterpriseUser"
  | "allowPublicSharing";

export type EnumDefnDeeplinkExpiry =
  | "onFirstClick"
  | "withinOneWeek"
  | "withinOneMonth"
  | "noExpiry";

export type EnumDefnDeploy =
  | "requiredOnDeploy"
  | "fixedOnDeploy";

export type EnumDefnDeviceSize =
  | "mobile"
  | "tablet"
  | "laptop"
  | "desktop";

export type EnumDefnDocFileExt =
  | "any"
  | "csv"
  | "dll"
  | "doc"
  | "docx"
  | "jar"
  | "jpeg"
  | "jpg"
  | "json"
  | "mp3"
  | "mp4"
  | "pdf"
  | "png"
  | "svg"
  | "wav"
  | "xls"
  | "xlsx"
  | "xml"
  | "zip";

export type EnumDefnDriveSheetFieldLayoutOn =
  | "column"
  | "header";

export type EnumDefnDriveSheetLayoutFor =
  | "sectionOrGrid"
  | "field";

export type EnumDefnDriveStatus =
  | "connected"
  | "disconnected"
  | "notInstalled";

export type EnumDefnDurationUnit =
  | "seconds"
  | "minutes"
  | "hours"
  | "days"
  | "weeks"
  | "months"
  | "quarters"
  | "years";

export type EnumDefnDynamicOperator =
  | "hasNoValue"
  | "hasValue"
  | "equalTo"
  | "notEqualTo";

export type EnumDefnEditorLayoutRenderingMode =
  | "tabs"
  | "stack"
  | "wizard";

export type EnumDefnEmptyFieldVariant =
  | "ignoreEmptyField"
  | "overrideEmptyField";

export type EnumDefnEntLockBehavior =
  | "readAccess"
  | "zeroAccess"
  | "fullAccessWithWarning";

export type EnumDefnEntLockReason =
  | "blocked"
  | "obsolete"
  | "other"
  | "paymentNotReceived"
  | "tooMuchLoad"
  | "userUpgrade";

export type EnumDefnErrorSeverity =
  | "error"
  | "suggestion"
  | "warning";

export type EnumDefnFields =
  | "$CreatedBy"
  | "$CreatedOn"
  | "$RowId"
  | "$RowOrder"
  | "$UpdatedBy"
  | "$UpdatedOn"
  | "$ParentRowId";

export type EnumDefnFormLayoutType =
  | "editor"
  | "content"
  | "template"
  | "report" //TODO: temporary added, remove this once all report type is converted into content

export type EnumDefnForms =
  | "$FormMapOfOptions"
  | "$FormPickTree"
  | "$FormSetOfUser"
  | "$FormPluginConfig"
  | "$FormSchedulerTrigger"
  | "$FormRazorpayPaymentReceipt"
  | "$FormNeomeComment"
  | "$FormNeomeLocation"
  | "$FormEntAdmin"
  | "$FormEntUser";

export type EnumDefnFuncArg =
  | "number"
  | "text"
  | "numberArray"
  | "textArray";

export type EnumDefnGridRenderingMode =
  | "auto"
  | "flex";

export type EnumDefnInsertVariant =
  | "insertForced"
  | "skipEmptyKeyField"
  | "doNotInsert";

export type EnumDefnKindAction =
  | "report"
  | "rowInsert"
  | "spreadsheetEditor";

export type EnumDefnKindAutomation =
  | "scheduled"
  | "spreadsheet"
  | "pluginWebhook"
  | "webhook";

export type EnumDefnKindAutomationStep =
  | "addUser"
  | "calculateFormulas"
  | "callPlugin"
  | "callReport"
  | "copyField"
  | "forwardToGroups"
  | "forwardToUsers"
  | "generateDeeplink"
  | "getSpreadsheetRows"
  | "validation"
  | "insertIntoSpreadsheet"
  | "lock"
  | "messageSend"
  | "generatePdf"
  | "generatePaymentLink"
  | "messageSendOnWhatsapp"
  | "messageSendOnEmail"
  | "partitionSend"
  | "pause"
  | "removeField"
  | "removeFromSpreadsheet"
  | "removeRow"
  | "returnEvent"
  | "terminate"
  | "updateField"
  | "updateLogNumber"
  | "updateSpreadsheet";

export type EnumDefnKindButton =
  | "submit"
  | "reset"
  | "refresh"
  | "normal";

export type EnumDefnKindDeeplink =
  | "report"
  | "spreadsheetEditor"
  | "spreadsheetInsert"
  | "spreadsheetRow";

export type EnumDefnKindFormComposite =
  | "grid"
  | "section";

export type EnumDefnKindHyperlink =
  | "general"
  | "youtube";

export type EnumDefnKindImport =
  | "plugin";

export type EnumDefnKindMessage =
  | "audio"
  | "camera"
  | "document"
  | "image"
  | "linkText"
  | "location"
  | "text"
  | "video"
  | "voice";

export type EnumDefnKindPluginWebhookEvent =
  | "onCallback";

export type EnumDefnKindRating =
  | "heart_1"
  | "thumbs_2"
  | "star_3"
  | "star_4"
  | "star_5";

export type EnumDefnKindReport =
  | "composite"
  | "mapper"
  | "plugin"
  | "query"
  | "spreadsheet";

export type EnumDefnKindScheduledEvent =
  | "onExpiry";

export type EnumDefnKindSetOfUser =
  | "roles"
  | "fields"
  | "context";

export type EnumDefnKindSpreadsheetEvent =
  | "afterInsert"
  | "afterRemove"
  | "afterUpdate"
  | "beforeInsert"
  | "beforeRemove"
  | "beforeUpdate";

export type EnumDefnKindTranslation =
  | "text"
  | "paragraph";

export type EnumDefnKindWebhookEvent =
  | "onCallback";

export type EnumDefnLayoutCardFilterKind =
  | "tree"
  | "tab";

export type EnumDefnLayoutGridKind =
  | "card"
  | "list"
  | "map"
  | "table"
  | "kanban"
  | "calendar"
  | "xyChartBarGraph"
  | "xyChartLineChart"
  | "xyChartPieChart"
  | "xyChartDoughnut"
  | "xyChartScatterPlot";

export type EnumDefnLockOperation =
  | "acquire"
  | "release";

export type EnumDefnLogOperationKind =
  | "add"
  | "subtract"
  | "set";

export type EnumDefnMapPinShape =
  | "circle"
  | "circleBig"
  | "circleMedium"
  | "heart"
  | "heartBig"
  | "heartMedium"
  | "pin"
  | "square"
  | "squareBig"
  | "squareMedium"
  | "star"
  | "starBig"
  | "starMedium"
  | "triangle"
  | "triangleBig"
  | "triangleMedium";

export type EnumDefnMonth =
  | "January"
  | "February"
  | "March"
  | "April"
  | "May"
  | "June"
  | "July"
  | "August"
  | "September"
  | "October"
  | "November"
  | "December"
  | "CurrentMonth"
  | "PreviousMonth"
  | "NextMonth";

export type EnumDefnPaymentMethod =
  | "creditCard"
  | "debitCard";

export type EnumDefnPaymentMethodKind =
  | "netbanking"
  | "card"
  | "upi";

export type EnumDefnPaymentPlan =
  | "free"
  | "team"
  | "business"
  | "onPremise";

export type EnumDefnPaymentStatus =
  | "pending"
  | "paid"
  | "failed";

export type EnumDefnPermission =
  | "hide"
  | "read"
  | "writeOnInsert"
  | "writeOnce"
  | "invisible"
  | "write";

export type EnumDefnPlacement =
  | "top"
  | "bottom"
  | "start"
  | "end"
  | "center"
  | "diagonal"
  | "overlayTop"
  | "flexCenter"
  | "spaceBetween"
  | "justify";

export type EnumDefnPluginApiMethod =
  | "delete"
  | "get"
  | "patch"
  | "post"
  | "put"
  | "webhook";

export type EnumDefnPluginMode =
  | "agent"
  | "webService";

export type EnumDefnPluginResources =
  | "jar"
  | "rpc"
  | "dev";

export type EnumDefnPluginSecurityAccess =
  | "drive"
  | "network";

export type EnumDefnPosition =
  | "topLeft"
  | "topRight"
  | "bottomLeft"
  | "bottomRight";

export type EnumDefnPromptAction =
  | "insert"
  | "update"
  | "remove"
  | "get";

export type EnumDefnQuarter =
  | "Quarter_1"
  | "Quarter_2"
  | "Quarter_3"
  | "Quarter_4";

export type EnumDefnRefreshOn =
  | "refreshOnOpen"
  | "refreshOnSend"
  | "refreshOnDemand";

export type EnumDefnRemoveVariant =
  | "removeForced"
  | "doNotRemove";

export type EnumDefnMapRenderingMode =
  | "fixedLocation"
  | "liveLocation";

export type EnumDefnRenderingKind =
  | "a4Size"
  | "fullScreen"
  | "receiptSize"
  | "mm_48"
  | "mm_58"
  | "mm_72"
  | "mm_80"
  | "mm_104";

export type EnumDefnRepeatFrequencyKind =
  | "minutes"
  | "hours"
  | "days"
  | "weeks"
  | "months"
  | "years";

export type EnumDefnRoles =
  | "$Public"
  | "$Manager"
  | "$GrandManager"
  | "$AllManagers"
  | "$Assistants"
  | "$AllAssistants"
  | "$Self";

export type EnumDefnRowAuditTrail =
  | "insert"
  | "update"
  | "remove";

export type EnumDefnRowProperty =
  | "createdBy"
  | "updatedBy";

export type EnumDefnScanCodeType =
  | "barCode"
  | "qrCode";

export type EnumDefnSetupKind =
  | "quick"
  | "standard"
  | "advanced"
  | "focusDeploy"
  | "focusBackend"
  | "focusFrontend";

export type EnumDefnShowBorderKind =
  | "top"
  | "bottom"
  | "left"
  | "right";

export type EnumDefnSortOrder =
  | "ascending"
  | "descending";

export type EnumDefnStoreItem =
  | "template"
  | "application";

export type EnumDefnSyncMode =
  | "backup"
  | "biDirectional";

export type EnumDefnTableStyle =
  | "defaultStyle";

export type EnumDefnTextSize =
  | "body1"
  | "body2"
  | "button"
  | "caption"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "inherit"
  | "overline"
  | "subtitle1"
  | "subtitle2"
  | "subtitle3"
  | "subtitle4";

export type EnumDefnTextStyle =
  | "bold"
  | "italic"
  | "underlined"
  | "strikeout";

export type EnumDefnTextValidationPattern =
  | "aadhaar"
  | "gstin"
  | "pan";

export type EnumDefnThemeButtonSize =
  | "small"
  | "medium"
  | "large";

export type EnumDefnThemeButtonVariant =
  | "text"
  | "contained"
  | "outlined"
  | "icon";

export type EnumDefnThemeColor =
  | "transparent"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning"
  | "textPrimary"
  | "textSecondary"
  | "textDisabled"
  | "textInverse"
  | "primaryLight"
  | "secondaryLight"
  | "successLight"
  | "errorLight"
  | "infoLight"
  | "warningLight"
  | "primaryDark"
  | "secondaryDark"
  | "successDark"
  | "errorDark"
  | "infoDark"
  | "warningDark"
  | "amber"
  | "black"
  | "blue"
  | "cyan"
  | "deepOrange"
  | "deepPurple"
  | "green"
  | "grey"
  | "indigo"
  | "lightBlue"
  | "lightGreen"
  | "lime"
  | "orange"
  | "pink"
  | "purple"
  | "red"
  | "teal"
  | "white"
  | "yellow";

export type EnumDefnThemeColorShade =
  | "s50"
  | "s100"
  | "s200"
  | "s300"
  | "s400"
  | "s500";

export type EnumDefnThemeDirection =
  | "horizontal"
  | "vertical";

export type EnumDefnThemeDividerKind =
  | "thick"
  | "thin";

export type EnumDefnThemeFieldMargin =
  | "dense"
  | "none"
  | "normal";

export type EnumDefnThemeFieldSize =
  | "medium"
  | "small";

export type EnumDefnThemeFieldVariant =
  | "outlined"
  | "filled"
  | "standard";

export type EnumDefnThemeFormVariant =
  | "form"
  | "report";

export type EnumDefnThemeImageCorner =
  | "rectangle"
  | "circle"
  | "rounded";

export type EnumDefnThemePickMultiVariant =
  | "checkboxVertical"
  | "checkboxHorizontal"
  | "dropdown";

export type EnumDefnThemePickVariant =
  | "radioButtonVertical"
  | "radioButtonHorizontal"
  | "led"
  | "dropdown";

export type EnumDefnThemeSectionVariant =
  | "form"
  | "propertyEditor";

export type EnumDefnThemeStroke =
  | "dash"
  | "dotted"
  | "solid";

export type EnumDefnThemeTabVariant =
  | "standard"
  | "scrollable"
  | "fullWidth";

export type EnumDefnTime =
  | "now";

export type EnumDefnUpdateVariant =
  | "updateForced"
  | "doNotUpdate";

export type EnumDefnUserContext =
  | "caller"
  | "createdBy"
  | "updatedBy";

export type EnumDefnUserProperty =
  | "nickName"
  | "handle";

export type EnumDefnUserProps =
  | "firstName"
  | "lastName"
  | "fullName"
  | "email"
  | "mobileNumber"
  | "handle";

export type EnumDefnUserSettingOptions =
  | "text"
  | "number"
  | "decimal"
  | "pickOne"
  | "pickMany";

export type EnumDefnUserSettingValue =
  | "dataEntered"
  | "dataNotEntered";

export type EnumDefnVideoFormat =
  | "mp4";

export type EnumDefnVisibilityAction =
  | "blink"
  | "click"
  | "clear"
  | "setValue"
  | "disable"
  | "enable"
  | "executeAction"
  | "hidden"
  | "highlight"
  | "invisible"
  | "shake"
  | "visible";

export type EnumDefnVisibilityActionOn =
  | "component"
  | "field"
  | "sendButton";

export type EnumDefnVisibilityOperator =
  | "hasNoValue"
  | "hasValue"
  | "equalTo"
  | "notEqualTo"
  | "hasChanged";

export type EnumDeviceType =
  | "android"
  | "ios"
  | "web"
  | "widget"
  | "agent"
  | "test";

export type EnumEnvValidationError =
  | "cli"
  | "composite"
  | "invalidParam"
  | "notAccessible"
  | "notChanged"
  | "notFound"
  | "ruleViolation";

export type EnumFormContentPosition =
  | "start"
  | "flexCenter"
  | "end";

export type EnumIdentityProviderKind =
  | "apple"
  | "microsoft"
  | "google";

export type EnumLogItemType =
  | "text"
  | "tree"
  | "table";

export type EnumLogTableAlignment =
  | "left"
  | "center"
  | "right";

export type EnumLogTableTextStyle =
  | "plain"
  | "bold"
  | "italic"
  | "underline"
  | "strikeThrough"
  | "boldItalic"
  | "boldUnderline"
  | "boldStrikeThrough";

export type EnumLogTextType =
  | "neoQL"
  | "cli"
  | "json"
  | "plain"
  | "monospaced"
  | "javascript"
  | "html"
  | "xml";

export type EnumLogTreeItemType =
  | "keyValue"
  | "line";

export type EnumLogTreeLineCollapse =
  | "showCollapse"
  | "showExpand"
  | "ignore";

export type EnumMediaType =
  | "audio"
  | "document"
  | "icon"
  | "image"
  | "jar"
  | "avatar"
  | "sticker"
  | "thumbnail"
  | "video"
  | "voice";

export type EnumNeatPathCaption =
  | "About"
  | "Plugins"
  | "Roles"
  | "Variables"
  | "Translations"
  | "Forms"
  | "Reports"
  | "Spreadsheets"
  | "Actions"
  | "Groups"
  | "Deeplinks"
  | "DriveSheets"
  | "Deploy"
  | "Automation"
  | "Admins"
  | "Users"
  | "Details"
  | "API"
  | "Resources"
  | "Store";

export type EnumPaymentProviderKind =
  | "razorpay";

export type EnumSchemaColumnType =
  | "Boolean"
  | "Date"
  | "DateArray"
  | "Double"
  | "GeoHash"
  | "GeoPoint"
  | "Long"
  | "String"
  | "StringArray"
  | "SysId"
  | "SysIdArray";

export type EnumStoreItemArtifact =
  | "ent"
  | "plugin"
  | "template";

export type EnumStoreLabel =
  | "SalesForce"
  | "Zendesk"
  | "Hubspot"
  | "ServiceNow"
  | "Tally"
  | "Ticketing"
  | "Ffreedom"
  | "CRM";

export type EnumStudioCompType =
  | "bool"
  | "date"
  | "decimal"
  | "logDecimal"
  | "image"
  | "label"
  | "number"
  | "logNumber"
  | "paragraph"
  | "text"
  | "chipSet"
  | "chipSetDate"
  | "chipSetDateTime"
  | "chipSetDay"
  | "chipSetDeviceSize"
  | "chipSetDeviceType"
  | "chipSetTime"
  | "currency"
  | "icon"
  | "language"
  | "timeZone"
  | "pinShape"
  | "lineStroke"
  | "paymentStatus"
  | "month"
  | "quarter"
  | "textSize"
  | "messageKind"
  | "pickRole"
  | "pickText"
  | "pickTree"
  | "pickUser"
  | "pickGridRow"
  | "pickReportRow"
  | "setOfRole"
  | "setOfUser"
  | "setOfText"
  | "color"
  | "hyperlink"
  | "audio"
  | "camera"
  | "counter"
  | "logCounter"
  | "dateRange"
  | "dateTime"
  | "dateTimeRange"
  | "duration"
  | "email"
  | "handle"
  | "location"
  | "mobileNumber"
  | "rating"
  | "signature"
  | "slider"
  | "time"
  | "video"
  | "voice"
  | "geoPoint"
  | "rowId"
  | "symbol"
  | "button"
  | "divider"
  | "document"
  | "error"
  | "html"
  | "identifier"
  | "info"
  | "propertyMap"
  | "ref"
  | "refUser"
  | "refReport"
  | "scanCode"
  | "showCode"
  | "userId"
  | "dynamic"
  | "hyperlinkRow"
  | "grid"
  | "section";

export type EnumStudioVarKind =
  | "any"
  | "bool"
  | "buttonVariant"
  | "color"
  | "currency"
  | "date"
  | "dateTime"
  | "day"
  | "decimal"
  | "deviceSize"
  | "deviceType"
  | "direction"
  | "dividerKind"
  | "document"
  | "duration"
  | "email"
  | "textSize"
  | "html"
  | "hyperlink"
  | "icon"
  | "image"
  | "imageCorner"
  | "language"
  | "location"
  | "mapPinShape"
  | "mobileNumber"
  | "number"
  | "paragraph"
  | "ratingKind"
  | "stroke"
  | "symbol"
  | "text"
  | "placement"
  | "time"
  | "timeZone"
  | "month"
  | "quarter"
  | "condition"
  | "function"
  | "mapping"
  | "sequence"
  | "userSetting"
  | "mapOfText"
  | "setOfDate"
  | "setOfDay"
  | "setOfNumber"
  | "setOfText"
  | "setOfTime"
  | "setOfUser"
  | "tree";

export const EnumStrDtoLogTreeKeyValueType = [
  "neoQL",
  "json",
  "javascript",
  "xml",
  "regular"
];

export const EnumArrayDefnAdminDoNotOptionEnt = [
  "about",
  "actions",
  "admins",
  "automations",
  "dashboard",
  "deeplinks",
  "deploy",
  "manage",
  "payment",
  "deployPayment",
  "deployPlugins",
  "deployVariables",
  "driveSheets",
  "formEditContentLayout",
  "formEditDetails",
  "formEditFormLayouts",
  "formEditFormulas",
  "formEditPermissions",
  "formEditVisibility",
  "forms",
  "governance",
  "groups",
  "plugins",
  "prompts",
  "reports",
  "roles",
  "spreadsheets",
  "translations",
  "users",
  "variables",
  "virtualAssistant",
  "adminModules"
];

export const EnumArrayDefnAdminDoNotOptionPlugin = [
  "admins"
];

export const EnumArrayDefnAdminDoNotOptionStoreItem = [
  "admins"
];

export const EnumArrayDefnAdminPermissionType = [
  "doNotEdit",
  "doNotShow"
];

export const EnumArrayDefnArgBinder = [
  "argument",
  "context",
  "derived",
  "field",
  "input",
  "output",
  "spreadsheet",
  "sep",
  "variable",
  "constant",
  "response"
];

export const EnumArrayDefnArgBinderArgument = [
  "selectedSectionId",
  "selectedGridId",
  "selectedCompositeId",
  "selectedGridRowId"
];

export const EnumArrayDefnArgBinderContext = [
  "caller",
  "callerSetting",
  "ent",
  "form",
  "plugin",
  "row"
];

export const EnumArrayDefnArgBinderContextCaller = [
  "color",
  "entUserId",
  "handle",
  "managerId",
  "assistantIds",
  "allAssistantIds",
  "allManagerIds",
  "grandManagerId",
  "nickName",
  "userId",
  "email",
  "mobileNumber",
  "roles"
];

export const EnumArrayDefnArgBinderContextEnt = [
  "about",
  "id",
  "name"
];

export const EnumArrayDefnArgBinderContextForm = [
  "id",
  "name",
  "label"
];

export const EnumArrayDefnArgBinderContextPlugin = [
  "about",
  "id",
  "name"
];

export const EnumArrayDefnArgBinderContextRow = [
  "createdBy",
  "createdOn",
  "id",
  "order",
  "parentId",
  "type",
  "updatedBy",
  "updatedOn"
];

export const EnumArrayDefnAudioFormat = [
  "ogg",
  "mp3",
  "wav"
];

export const EnumArrayDefnAutomationSource = [
  "currentForm",
  "currentGrid"
];

export const EnumArrayDefnAutomationTerminateKind = [
  "terminate",
  "resume",
  "setField"
];

export const EnumArrayDefnAutomationWebhookKind = [
  "razorpayPaymentReceipt",
  "neomeComment",
  "location"
];

export const EnumArrayDefnButtonTargetType = [
  "saveToSpreadsheet",
  "invokePlugin",
  "callReport",
  "triggerAction"
];

export const EnumArrayDefnCalculateFormulaMode = [
  "automatic",
  "manual",
  "onSend"
];

export const EnumArrayDefnCaptureMode = [
  "manual",
  "onInsert",
  "onUpdate"
];

export const EnumArrayDefnCaptureValueKind = [
  "captureLocation",
  "captureTime",
  "captureUser"
];

export const EnumArrayDefnCodeEditorLanguage = [
  "text",
  "html",
  "javascript",
  "json",
  "neoQL",
  "sql",
  "xml"
];

export const EnumArrayDefnCodeType = [
  "barCode",
  "qrCode"
];

export const EnumArrayDefnCompType = [
  "bool",
  "date",
  "decimal",
  "logDecimal",
  "image",
  "label",
  "number",
  "logNumber",
  "paragraph",
  "text",
  "enumAdminDoNotOptionEnt",
  "enumAdminDoNotOptionPlugin",
  "enumAudioFormat",
  "enumAutomationSource",
  "enumCaptureValueKind",
  "enumCodeEditorLanguage",
  "enumConditionOperator",
  "enumConjunction",
  "enumDataPartitionPeriod",
  "enumDate",
  "enumDay",
  "enumDeeplinkConstraints",
  "enumDeployVar",
  "enumDeviceSize",
  "enumDeviceType",
  "enumDocFileExt",
  "enumDriveStatus",
  "enumDurationUnit",
  "enumEntLockBehavior",
  "enumEntLockReason",
  "enumFields",
  "enumFuncArgs",
  "enumMapPinShape",
  "enumPaymentMethod",
  "enumPaymentPlan",
  "enumPermission",
  "enumPluginApiMethod",
  "enumPluginResources",
  "enumPluginSecurityAccess",
  "enumPromptAction",
  "enumRoles",
  "enumRowAuditTrail",
  "enumSetupKind",
  "enumUserSettingOptions",
  "enumUserSettingValue",
  "enumVideoFormat",
  "enumVisibilityOperator",
  "enumMonth",
  "enumQuarter",
  "enumDeeplinkExpiry",
  "enumForms",
  "enumLogOperationKind",
  "enumCodeType",
  "enumPosition",
  "enumDateOccurrence",
  "enumFrequencyKind",
  "enumRenderingKind",
  "enumInsertVariant",
  "enumUpdateVariant",
  "enumRemoveVariant",
  "enumEmptyFieldVariant",
  "enumTableLayoutStyle",
  "enumUserProperty",
  "enumRowProperty",
  "enumStoreItem",
  "enumCaptureMode",
  "enumLockOperation",
  "enumRefreshOn",
  "enumEditorLayoutRenderingMode",
  "enumFormContentLayoutType",
  "enumSetOfUserKind",
  "enumUserContext",
  "enumDynamicOperator",
  "enumTargetType",
  "enumDriveSheetLayoutFor",
  "enumDriveSheetFieldLayoutOn",
  "enumTextStyle",
  "enumContentAlignment",
  "enumUserProps",
  "enumGridRenderingMode",
  "enumPaymentMethodKind",
  "enumSortOrder",
  "enumTextValidationPattern",
  "enumSyncMode",
  "enumPluginMode",
  "enumArgBinderContext",
  "enumCalculateFormulaMode",
  "enumArgBinder",
  "enumKindAction",
  "enumKindAutomation",
  "enumKindButton",
  "enumKindDeeplink",
  "enumKindFormComposite",
  "enumKindHyperlink",
  "enumKindImport",
  "enumKindRating",
  "enumKindReport",
  "enumKindScheduledEvent",
  "enumKindAutomationStep",
  "enumKindSpreadsheetEvent",
  "enumKindPluginWebhookEvent",
  "enumKindWebhookEvent",
  "enumKindTranslation",
  "enumTerminateSetting",
  "enumAutomationWebhookKind",
  "enumLayoutCardFilterKind",
  "enumLayoutGridKind",
  "enumThemeButtonSize",
  "enumThemeButtonVariant",
  "enumThemeColor",
  "enumThemeColorShade",
  "enumThemeDirection",
  "enumThemeDividerThickness",
  "enumThemeFieldMargin",
  "enumThemeFieldSize",
  "enumThemeFieldVariant",
  "enumThemeFormVariant",
  "enumThemeImageCorner",
  "enumThemePickVariant",
  "enumThemePickMultiVariant",
  "enumPlacement",
  "enumThemeSectionVariant",
  "enumThemeStroke",
  "enumThemeTabVariant",
  "enumVisibilityAction",
  "enumVisibilityActionOn",
  "currency",
  "icon",
  "language",
  "timeZone",
  "pinShape",
  "lineStroke",
  "month",
  "quarter",
  "textSize",
  "paymentStatus",
  "messageKind",
  "chipSet",
  "chipSetDate",
  "chipSetDateTime",
  "chipSetDay",
  "chipSetDeviceSize",
  "chipSetDeviceType",
  "setOfLanguageKeys",
  "chipSetTime",
  "pickRole",
  "pickText",
  "pickTree",
  "pickUser",
  "pickGridRow",
  "pickReportRow",
  "pickOption",
  "setOfRole",
  "setOfUser",
  "setOfText",
  "color",
  "hyperlink",
  "audio",
  "camera",
  "counter",
  "logCounter",
  "dateRange",
  "dateTime",
  "dateTimeRange",
  "duration",
  "email",
  "handle",
  "location",
  "mobileNumber",
  "rating",
  "signature",
  "slider",
  "time",
  "video",
  "voice",
  "geoPoint",
  "rowId",
  "symbol",
  "button",
  "divider",
  "document",
  "error",
  "html",
  "identifier",
  "info",
  "propertyMap",
  "ref",
  "refUser",
  "refReport",
  "scanCode",
  "showCode",
  "userId",
  "dynamic",
  "hyperlinkRow",
  "grid",
  "section",
  "tab",
  "wizard",
  "dateFormat",
  "studioVarIdTextEditor",
  "studioVarIdParagraphEditor",
  "studioCodeEditor",
  "pickActionId",
  "pickCompId",
  "pickPluginCompId",
  "pickFieldId",
  "pickPluginFieldId",
  "pickFormId",
  "pickPluginFormId",
  "pickGridId",
  "pickImportPluginId",
  "pickImportPluginApiId",
  "pickLayoutFormContentId",
  "pickLayoutGridId",
  "pickLayoutSpreadsheetId",
  "pickPluginBundleId",
  "pickPluginId",
  "pickReportId",
  "pickSectionId",
  "pickSpreadsheetId",
  "pickVarId",
  "pickGroupId",
  "pickDeeplinkId",
  "pickPipelineVarId",
  "pickDeployPaymentProviderId",
  "studioBuildAllModules",
  "studioBuildArgBinder",
  "studioSetOfDate",
  "studioBuildColor",
  "studioBuildDate",
  "studioBuildDateTime",
  "studioBuildPermissionMatrix",
  "studioBuildTree",
  "studioBuildUserSetting",
  "studioBuildActionPermission",
  "studioBuildPropertyMap",
  "studioMapOfForwardRolePermission",
  "studioMapOfCondition",
  "studioMapOfFormula",
  "studioMapOfFuncArg",
  "studioMapOfJarFile",
  "studioMapOfLayoutSpreadsheet",
  "studioMapOfLayoutGrid",
  "studioMapOfText",
  "studioMapOfVisibilityCondition",
  "studioMapOfVisibilityAction",
  "studioMapOfPartition",
  "studioMapOfForwardGroupPermission",
  "studioMapOfDynamicRule",
  "studioMapOfPipelineVariable",
  "studioMapOfDynamicCondition",
  "studioMapOfUserCondition",
  "studioMapOfLayoutDriveSpreadsheet",
  "studioFieldMappingTree",
  "studioGridMappingTree",
  "studioMapOfArgBinder",
  "studioSetOfDocFileExt",
  "studioSetOfStoreItemCategory",
  "studioSetOfModule",
  "studioSetOfNumber",
  "studioSetOfAdminDoNotOption",
  "studioSetOfPluginSecurityAccess",
  "studioSetOfRowAuditTrail",
  "studioSetOfMonth",
  "studioSetOfBorder",
  "studioSetOfCompId",
  "studioSetOfDataExportKind",
  "studioSetOfActionId",
  "studioSetOfFieldId",
  "studioSetOfFieldRefId",
  "studioSetOfFormId",
  "studioSetOfGridId",
  "studioSetOfGroupId",
  "studioSetOfLayoutFormContentId",
  "studioSetOfLayoutGridId",
  "studioSetOfLayoutSpreadsheetId",
  "studioSetOfReportId",
  "studioSetOfSectionId",
  "studioSetOfSpreadsheetId",
  "studioSetOfVarId",
  "studioCompArray",
  "otp",
  "avtar",
  "carousel",
  "formList",
  "formListItem",
  "password"
];

export const EnumArrayDefnConditionOperator = [
  "hasNoValue",
  "equalTo",
  "greaterThan",
  "greaterThanOrEqualTo",
  "hasValue",
  "lessThan",
  "lessThanOrEqualTo",
  "notEqualTo"
];

export const EnumArrayDefnConjunction = [
  "or",
  "and"
];

export const EnumArrayDefnContentAlignment = [
  "start",
  "center",
  "end"
];

export const EnumArrayDefnDataExportKind = [
  "xlsx",
  "json"
];

export const EnumArrayDefnDataPartitionPeriod = [
  "daily",
  "hourly",
  "weekly",
  "monthly",
  "quarterly",
  "yearly"
];

export const EnumArrayDefnDate = [
  "lastQuarter",
  "lastWeek",
  "lastMonth",
  "lastYear",
  "nextQuarter",
  "nextWeek",
  "nextMonth",
  "nextYear",
  "now",
  "tomorrow",
  "yesterday",
  "startOfWeek",
  "startOfMonth",
  "startOfYear",
  "endOfWeek",
  "endOfMonth",
  "endOfYear",
  "createdOn",
  "updatedOn"
];

export const EnumArrayDefnDateOccurrence = [
  "custom",
  "first",
  "last"
];

export const EnumArrayDefnDay = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday"
];

export const EnumArrayDefnDeeplinkConstraint = [
  "enforceGlobalUserSignIn",
  "enforceEnterpriseUserSignIn",
  "makeAnEnterpriseUser",
  "allowPublicSharing"
];

export const EnumArrayDefnDeeplinkExpiry = [
  "onFirstClick",
  "withinOneWeek",
  "withinOneMonth",
  "noExpiry"
];

export const EnumArrayDefnDeploy = [
  "requiredOnDeploy",
  "fixedOnDeploy"
];

export const EnumArrayDefnDeviceSize = [
  "mobile",
  "tablet",
  "laptop",
  "desktop"
];

export const EnumArrayDefnDocFileExt = [
  "any",
  "csv",
  "dll",
  "doc",
  "docx",
  "jar",
  "jpeg",
  "jpg",
  "json",
  "mp3",
  "mp4",
  "pdf",
  "png",
  "svg",
  "wav",
  "xls",
  "xlsx",
  "xml",
  "zip"
];

export const EnumArrayDefnDriveSheetFieldLayoutOn = [
  "column",
  "header"
];

export const EnumArrayDefnDriveSheetLayoutFor = [
  "sectionOrGrid",
  "field"
];

export const EnumArrayDefnDriveStatus = [
  "connected",
  "disconnected",
  "notInstalled"
];

export const EnumArrayDefnDurationUnit = [
  "seconds",
  "minutes",
  "hours",
  "days",
  "weeks",
  "months",
  "quarters",
  "years"
];

export const EnumArrayDefnDynamicOperator = [
  "hasNoValue",
  "hasValue",
  "equalTo",
  "notEqualTo"
];

export const EnumArrayDefnEditorLayoutRenderingMode = [
  "tabs",
  "stack",
  "wizard"
];

export const EnumArrayDefnEmptyFieldVariant = [
  "ignoreEmptyField",
  "overrideEmptyField"
];

export const EnumArrayDefnEntLockBehavior = [
  "readAccess",
  "zeroAccess",
  "fullAccessWithWarning"
];

export const EnumArrayDefnEntLockReason = [
  "blocked",
  "obsolete",
  "other",
  "paymentNotReceived",
  "tooMuchLoad",
  "userUpgrade"
];

export const EnumArrayDefnErrorSeverity = [
  "error",
  "suggestion",
  "warning"
];

export const EnumArrayDefnFields = [
  "$CreatedBy",
  "$CreatedOn",
  "$RowId",
  "$RowOrder",
  "$UpdatedBy",
  "$UpdatedOn",
  "$ParentRowId"
];

export const EnumArrayDefnFormLayoutType = [
  "editor",
  "content",
  "template"
];

export const EnumArrayDefnForms = [
  "$FormMapOfOptions",
  "$FormPickTree",
  "$FormSetOfUser",
  "$FormPluginConfig",
  "$FormSchedulerTrigger",
  "$FormRazorpayPaymentReceipt",
  "$FormNeomeComment",
  "$FormNeomeLocation",
  "$FormEntAdmin",
  "$FormEntUser"
];

export const EnumArrayDefnFuncArg = [
  "number",
  "text",
  "numberArray",
  "textArray"
];

export const EnumArrayDefnGridRenderingMode = [
  "auto",
  "flex"
];

export const EnumArrayDefnInsertVariant = [
  "insertForced",
  "skipEmptyKeyField",
  "doNotInsert"
];

export const EnumArrayDefnKindAction = [
  "report",
  "rowInsert",
  "spreadsheetEditor"
];

export const EnumArrayDefnKindAutomation = [
  "scheduled",
  "spreadsheet",
  "pluginWebhook",
  "webhook"
];

export const EnumArrayDefnKindAutomationStep = [
  "addUser",
  "calculateFormulas",
  "callPlugin",
  "callReport",
  "copyField",
  "forwardToGroups",
  "forwardToUsers",
  "generateDeeplink",
  "getSpreadsheetRows",
  "validation",
  "insertIntoSpreadsheet",
  "lock",
  "messageSend",
  "generatePdf",
  "generatePaymentLink",
  "messageSendOnWhatsapp",
  "messageSendOnEmail",
  "partitionSend",
  "pause",
  "removeField",
  "removeFromSpreadsheet",
  "removeRow",
  "returnEvent",
  "terminate",
  "updateField",
  "updateLogNumber",
  "updateSpreadsheet"
];

export const EnumArrayDefnKindButton = [
  "submit",
  "reset",
  "refresh",
  "normal"
];

export const EnumArrayDefnKindDeeplink = [
  "report",
  "spreadsheetEditor",
  "spreadsheetInsert",
  "spreadsheetRow"
];

export const EnumArrayDefnKindFormComposite = [
  "grid",
  "section"
];

export const EnumArrayDefnKindHyperlink = [
  "general",
  "youtube"
];

export const EnumArrayDefnKindImport = [
  "plugin"
];

export const EnumArrayDefnKindMessage = [
  "audio",
  "camera",
  "document",
  "image",
  "linkText",
  "location",
  "text",
  "video",
  "voice"
];

export const EnumArrayDefnKindPluginWebhookEvent = [
  "onCallback"
];

export const EnumArrayDefnKindRating = [
  "heart_1",
  "thumbs_2",
  "star_3",
  "star_4",
  "star_5"
];

export const EnumArrayDefnKindReport = [
  "composite",
  "mapper",
  "plugin",
  "query",
  "spreadsheet"
];

export const EnumArrayDefnKindScheduledEvent = [
  "onExpiry"
];

export const EnumArrayDefnKindSetOfUser = [
  "roles",
  "fields",
  "context"
];

export const EnumArrayDefnKindSpreadsheetEvent = [
  "afterInsert",
  "afterRemove",
  "afterUpdate",
  "beforeInsert",
  "beforeRemove",
  "beforeUpdate"
];

export const EnumArrayDefnKindTranslation = [
  "text",
  "paragraph"
];

export const EnumArrayDefnKindWebhookEvent = [
  "onCallback"
];

export const EnumArrayDefnLayoutCardFilterKind = [
  "tree",
  "tab"
];

export const EnumArrayDefnLayoutGridKind = [
  "card",
  "list",
  "map",
  "table",
  "kanban",
  "calendar",
  "xyChartBarGraph",
  "xyChartLineChart",
  "xyChartPieChart",
  "xyChartDoughnut",
  "xyChartScatterPlot"
];

export const EnumArrayDefnLockOperation = [
  "acquire",
  "release"
];

export const EnumArrayDefnLogOperationKind = [
  "add",
  "subtract",
  "set"
];

export const EnumArrayDefnMapPinShape = [
  "circle",
  "circleBig",
  "circleMedium",
  "heart",
  "heartBig",
  "heartMedium",
  "pin",
  "square",
  "squareBig",
  "squareMedium",
  "star",
  "starBig",
  "starMedium",
  "triangle",
  "triangleBig",
  "triangleMedium"
];

export const EnumArrayDefnMonth = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
  "CurrentMonth",
  "PreviousMonth",
  "NextMonth"
];

export const EnumArrayDefnPaymentMethod = [
  "creditCard",
  "debitCard"
];

export const EnumArrayDefnPaymentMethodKind = [
  "netbanking",
  "card",
  "upi"
];

export const EnumArrayDefnPaymentPlan = [
  "free",
  "team",
  "business",
  "onPremise"
];

export const EnumArrayDefnPaymentStatus = [
  "pending",
  "paid",
  "failed"
];

export const EnumArrayDefnPermission = [
  "hide",
  "read",
  "writeOnInsert",
  "writeOnce",
  "invisible",
  "write"
];

export const EnumArrayDefnPlacement = [
  "top",
  "bottom",
  "start",
  "end",
  "center",
  "diagonal",
  "overlayTop",
  "flexCenter",
  "spaceBetween",
  "justify"
];

export const EnumArrayDefnPluginApiMethod = [
  "delete",
  "get",
  "patch",
  "post",
  "put",
  "webhook"
];

export const EnumArrayDefnPluginMode = [
  "agent",
  "webService"
];

export const EnumArrayDefnPluginResources = [
  "jar",
  "rpc",
  "dev"
];

export const EnumArrayDefnPluginSecurityAccess = [
  "drive",
  "network"
];

export const EnumArrayDefnPosition = [
  "topLeft",
  "topRight",
  "bottomLeft",
  "bottomRight"
];

export const EnumArrayDefnPromptAction = [
  "insert",
  "update",
  "remove",
  "get"
];

export const EnumArrayDefnQuarter = [
  "Quarter_1",
  "Quarter_2",
  "Quarter_3",
  "Quarter_4"
];

export const EnumArrayDefnRefreshOn = [
  "refreshOnOpen",
  "refreshOnSend",
  "refreshOnDemand"
];

export const EnumArrayDefnRemoveVariant = [
  "removeForced",
  "doNotRemove"
];

export const EnumArrayDefnRenderingKind = [
  "a4Size",
  "fullScreen",
  "receiptSize",
  "mm_48",
  "mm_58",
  "mm_72",
  "mm_80",
  "mm_104"
];

export const EnumArrayDefnMapRenderingMode = [
  "fixedLocation",
  "liveLocation"
];

export const EnumArrayDefnRepeatFrequencyKind = [
  "minutes",
  "hours",
  "days",
  "weeks",
  "months",
  "years"
];

export const EnumArrayDefnRoles = [
  "$Public",
  "$Manager",
  "$GrandManager",
  "$AllManagers",
  "$Assistants",
  "$AllAssistants",
  "$Self"
];

export const EnumArrayDefnRowAuditTrail = [
  "insert",
  "update",
  "remove"
];

export const EnumArrayDefnRowProperty = [
  "createdBy",
  "updatedBy"
];

export const EnumArrayDefnScanCodeType = [
  "barCode",
  "qrCode"
];

export const EnumArrayDefnSetupKind = [
  "quick",
  "standard",
  "advanced",
  "focusDeploy",
  "focusBackend",
  "focusFrontend"
];

export const EnumArrayDefnShowBorderKind = [
  "top",
  "bottom",
  "left",
  "right"
];

export const EnumArrayDefnSortOrder = [
  "ascending",
  "descending"
];

export const EnumArrayDefnStoreItem = [
  "template",
  "application"
];

export const EnumArrayDefnSyncMode = [
  "backup",
  "biDirectional"
];

export const EnumArrayDefnTableStyle = [
  "defaultStyle"
];

export const EnumArrayDefnTextSize = [
  "body1",
  "body2",
  "button",
  "caption",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "inherit",
  "overline",
  "subtitle1",
  "subtitle2",
  "subtitle3",
  "subtitle4"
];

export const EnumArrayDefnTextStyle = [
  "bold",
  "italic",
  "underlined",
  "strikeout"
];

export const EnumArrayDefnTextValidationPattern = [
  "aadhaar",
  "gstin",
  "pan"
];

export const EnumArrayDefnThemeButtonSize = [
  "small",
  "medium",
  "large"
];

export const EnumArrayDefnThemeButtonVariant = [
  "text",
  "contained",
  "outlined",
  "icon"
];

export const EnumArrayDefnThemeColor = [
  "transparent",
  "primary",
  "secondary",
  "error",
  "info",
  "success",
  "warning",
  "textPrimary",
  "textSecondary",
  "textDisabled",
  "textInverse",
  "primaryLight",
  "secondaryLight",
  "successLight",
  "errorLight",
  "infoLight",
  "warningLight",
  "primaryDark",
  "secondaryDark",
  "successDark",
  "errorDark",
  "infoDark",
  "warningDark",
  "amber",
  "black",
  "blue",
  "cyan",
  "deepOrange",
  "deepPurple",
  "green",
  "grey",
  "indigo",
  "lightBlue",
  "lightGreen",
  "lime",
  "orange",
  "pink",
  "purple",
  "red",
  "teal",
  "white",
  "yellow"
];

export const EnumArrayDefnThemeColorShade = [
  "s50",
  "s100",
  "s200",
  "s300",
  "s400",
  "s500"
];

export const EnumArrayDefnThemeDirection = [
  "horizontal",
  "vertical"
];

export const EnumArrayDefnThemeDividerKind = [
  "thick",
  "thin"
];

export const EnumArrayDefnThemeFieldMargin = [
  "dense",
  "none",
  "normal"
];

export const EnumArrayDefnThemeFieldSize = [
  "medium",
  "small",
  "extraSmall"
];

export const EnumArrayDefnThemeFieldVariant = [
  "outlined",
  "filled",
  "standard"
];

export const EnumArrayDefnThemeFormVariant = [
  "form",
  "report"
];

export const EnumArrayDefnThemeImageCorner = [
  "rectangle",
  "circle",
  "rounded"
];

export const EnumArrayDefnThemePickMultiVariant = [
  "checkboxVertical",
  "checkboxHorizontal",
  "dropdown"
];

export const EnumArrayDefnThemePickVariant = [
  "radioButtonVertical",
  "radioButtonHorizontal",
  "led",
  "dropdown"
];

export const EnumArrayDefnThemeSectionVariant = [
  "form",
  "propertyEditor"
];

export const EnumArrayDefnThemeStroke = [
  "dash",
  "dotted",
  "solid"
];

export const EnumArrayDefnThemeTabVariant = [
  "standard",
  "scrollable",
  "fullWidth"
];

export const EnumArrayDefnTime = [
  "now"
];

export const EnumArrayDefnUpdateVariant = [
  "updateForced",
  "doNotUpdate"
];

export const EnumArrayDefnUserContext = [
  "caller",
  "createdBy",
  "updatedBy"
];

export const EnumArrayDefnUserProperty = [
  "nickName",
  "handle"
];

export const EnumArrayDefnUserProps = [
  "firstName",
  "lastName",
  "fullName",
  "email",
  "mobileNumber",
  "handle"
];

export const EnumArrayDefnUserSettingOptions = [
  "text",
  "number",
  "decimal",
  "pickOne",
  "pickMany"
];

export const EnumArrayDefnUserSettingValue = [
  "dataEntered",
  "dataNotEntered"
];

export const EnumArrayDefnVideoFormat = [
  "mp4"
];

export const EnumArrayDefnVisibilityAction = [
  "blink",
  "click",
  "clear",
  "setValue",
  "disable",
  "enable",
  "executeAction",
  "hidden",
  "highlight",
  "invisible",
  "shake",
  "visible"
];

export const EnumArrayDefnVisibilityActionOn = [
  "component",
  "field",
  "sendButton"
];

export const EnumArrayDefnVisibilityOperator = [
  "hasNoValue",
  "hasValue",
  "equalTo",
  "notEqualTo",
  "hasChanged"
];

export const EnumArrayDeviceType = [
  "android",
  "ios",
  "web",
  "widget",
  "agent",
  "test"
];

export const EnumArrayEnvValidationError = [
  "cli",
  "composite",
  "invalidParam",
  "notAccessible",
  "notChanged",
  "notFound",
  "ruleViolation"
];

export const EnumArrayFormContentPosition = [
  "start",
  "flexCenter",
  "end"
];

export const EnumArrayIdentityProviderKind = [
  "apple",
  "microsoft",
  "google"
];

export const EnumArrayLogItemType = [
  "text",
  "tree",
  "table"
];

export const EnumArrayLogTableAlignment = [
  "left",
  "center",
  "right"
];

export const EnumArrayLogTableTextStyle = [
  "plain",
  "bold",
  "italic",
  "underline",
  "strikeThrough",
  "boldItalic",
  "boldUnderline",
  "boldStrikeThrough"
];

export const EnumArrayLogTextType = [
  "neoQL",
  "cli",
  "json",
  "plain",
  "monospaced",
  "javascript",
  "html",
  "xml"
];

export const EnumArrayLogTreeItemType = [
  "keyValue",
  "line"
];

export const EnumArrayLogTreeLineCollapse = [
  "showCollapse",
  "showExpand",
  "ignore"
];

export const EnumArrayMediaType = [
  "audio",
  "document",
  "icon",
  "image",
  "jar",
  "avatar",
  "sticker",
  "thumbnail",
  "video",
  "voice"
];

export const EnumArrayNeatPathCaption = [
  "About",
  "Plugins",
  "Roles",
  "Variables",
  "Translations",
  "Forms",
  "Reports",
  "Spreadsheets",
  "Actions",
  "Groups",
  "Deeplinks",
  "DriveSheets",
  "Deploy",
  "Automation",
  "Admins",
  "Users",
  "Details",
  "API",
  "Resources",
  "Store"
];

export const EnumArrayPaymentProviderKind = [
  "razorpay"
];

export const EnumArraySchemaColumnType = [
  "Boolean",
  "Date",
  "DateArray",
  "Double",
  "GeoHash",
  "GeoPoint",
  "Long",
  "String",
  "StringArray",
  "SysId",
  "SysIdArray"
];

export const EnumArrayStoreItemArtifact = [
  "ent",
  "plugin",
  "template"
];

export const EnumArrayStoreLabel = [
  "SalesForce",
  "Zendesk",
  "Hubspot",
  "ServiceNow",
  "Tally",
  "Ticketing",
  "Ffreedom",
  "CRM"
];

export const EnumArrayStudioCompType = [
  "bool",
  "date",
  "decimal",
  "logDecimal",
  "image",
  "label",
  "number",
  "logNumber",
  "paragraph",
  "text",
  "chipSet",
  "chipSetDate",
  "chipSetDateTime",
  "chipSetDay",
  "chipSetDeviceSize",
  "chipSetDeviceType",
  "chipSetTime",
  "currency",
  "icon",
  "language",
  "timeZone",
  "pinShape",
  "lineStroke",
  "paymentStatus",
  "month",
  "quarter",
  "textSize",
  "messageKind",
  "pickRole",
  "pickText",
  "pickTree",
  "pickUser",
  "pickGridRow",
  "pickReportRow",
  "setOfRole",
  "setOfUser",
  "setOfText",
  "color",
  "hyperlink",
  "audio",
  "camera",
  "counter",
  "logCounter",
  "dateRange",
  "dateTime",
  "dateTimeRange",
  "duration",
  "email",
  "handle",
  "location",
  "mobileNumber",
  "rating",
  "signature",
  "slider",
  "time",
  "video",
  "voice",
  "geoPoint",
  "rowId",
  "symbol",
  "button",
  "divider",
  "document",
  "error",
  "html",
  "identifier",
  "info",
  "propertyMap",
  "ref",
  "refUser",
  "refReport",
  "scanCode",
  "showCode",
  "userId",
  "dynamic",
  "hyperlinkRow",
  "grid",
  "section"
];

export const EnumArrayStudioVarKind = [
  "any",
  "bool",
  "buttonVariant",
  "color",
  "currency",
  "date",
  "dateTime",
  "day",
  "decimal",
  "deviceSize",
  "deviceType",
  "direction",
  "dividerKind",
  "document",
  "duration",
  "email",
  "textSize",
  "html",
  "hyperlink",
  "icon",
  "image",
  "imageCorner",
  "language",
  "location",
  "mapPinShape",
  "mobileNumber",
  "number",
  "paragraph",
  "ratingKind",
  "stroke",
  "symbol",
  "text",
  "placement",
  "time",
  "timeZone",
  "month",
  "quarter",
  "condition",
  "function",
  "mapping",
  "sequence",
  "userSetting",
  "mapOfText",
  "setOfDate",
  "setOfDay",
  "setOfNumber",
  "setOfText",
  "setOfTime",
  "setOfUser",
  "tree"
];
